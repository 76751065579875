<template>
  <v-footer class="public-sign__footer text-caption font-weight-regular white--text">
    <v-spacer></v-spacer>
    Powered by
    <img src="@/layouts/assets/signi_logo.png" width="auto" height="25px" alt="Signi logo" />
    <v-spacer></v-spacer>
  </v-footer>
</template>

<script>
export default {
  name: 'PublicSignFooter',
};
</script>

<style lang="scss" scoped>
.public-sign__footer {
  background: rgba(0, 0, 0, 0.5) !important;
}
</style>
