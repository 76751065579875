<template>
  <v-app class="public-sign" :style="`background-image: url(${background})`">
    <PublicSignAppBar />

    <v-main>
      <v-container
        :class="{
          'fill-height': !contentInCenter,
          'ma-0 pa-0': contentInCenter,
        }"
        fluid
      >
        <slot></slot>
      </v-container>
    </v-main>

    <PublicSignFooter />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PublicSignAppBar from '@/layouts/components/PublicSignAppBar';
import PublicSignFooter from '@/layouts/components/PublicSignFooter';
import { loadLocaleForUnregistered } from '@/plugins/i18n';

export default {
  name: 'PublicSignLayout',
  components: {
    PublicSignAppBar,
    PublicSignFooter,
  },
  computed: {
    ...mapGetters({
      contractSettings: 'publicContract/settings',
    }),
    background() {
      return this.contractSettings?.background || require('./assets/public_sign_background.jpg');
    },
    contentInCenter() {
      return 'contractView' === this.$route.name;
    },
  },
  created() {
    const routeNames = ['public-sign-expired-link', 'public-sign-rejected'];

    if (routeNames.includes(this.$route.name)) {
      return;
    }

    this.fetchContract(this.$route.params.hash).then((resp) => {
      loadLocaleForUnregistered(this, resp);
    });
  },
  methods: {
    ...mapActions({
      fetchContract: 'publicContract/fetchContract',
    }),
  },
};
</script>

<style lang="scss" scoped>
.public-sign {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}
</style>
