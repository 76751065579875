<template>
  <v-app-bar class="public-sign__app-bar" flat app>
    <v-spacer></v-spacer>

    <v-btn
      class="px-3 mx-3"
      :text="!isMobileDevice"
      :icon="isMobileDevice"
      dark
      @click="openExternalLink('links.faq')"
    >
      <v-icon :left="!isMobileDevice">mdi-frequently-asked-questions</v-icon>
      <template v-if="!isMobileDevice">
        {{ $t('signing.signing_faq') }}
      </template>
    </v-btn>

    <LanguageToggle :locale="currentLocale" @onLocaleChanged="changeLocale($event)" />
  </v-app-bar>
</template>

<script>
import LanguageToggle from '@/plugins/i18n/components/LanguageToggle';
import { changeLocaleWithoutSave, getCurrentLocale } from '@/plugins/i18n';
import { goToLinkWithTarget } from '@/common/reusable/routing';

export default {
  name: 'PublicSignAppBar',
  components: {
    LanguageToggle,
  },
  computed: {
    currentLocale() {
      return getCurrentLocale(this);
    },
    isMobileDevice() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    changeLocale(locale) {
      const isChangedLocale = changeLocaleWithoutSave(this, locale);

      localStorage.setItem('sign_app_locale', locale);

      if (isChangedLocale) {
        this.actualLocale = locale;
      }
    },
    openExternalLink(externalLinkKey) {
      goToLinkWithTarget(this.$t(externalLinkKey));
    },
  },
};
</script>

<style lang="scss" scoped>
.public-sign__app-bar {
  background: rgba(0, 0, 0, 0.5) !important;
}
</style>
